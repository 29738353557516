<template>
  <div class="culture-box">
    <Pbanner :pbanner="pbanner"/>
    <div class="leval-menu">
        <div class="leval-menu-wrap inner">
          <Levalmenu :menuNumber="menuNumber"/>
          <div class="posites">
            <span>所在位置：</span>
            <router-link to="/home">首页</router-link>
            <b>></b>
            <router-link to="/about/about">关于安吉</router-link>
            <b>></b>
            <router-link to="/about/culture">企业文化</router-link>
          </div>
        </div>  
    </div>
    <div class="culture-top" :style="{backgroundImage:`url(${cultureBg})`}">
      <div class="culture-title">
        <small>company culture</small>
        <strong>企业<b>文化</b></strong>
      </div>
      <div class="culture-text" v-html="cultureFix.content">
      </div>
    </div>
    <ul class="culture-list">
      <li v-for="item in cultureFix.list1" :key="item.id">
        <figure><img :src="item.thumb" alt=""/></figure>
        <div class="cuture-in">
          <span>我们的</span>
          <strong>{{item.title}}</strong>
          <p v-html="item.description"></p>
        </div>
      </li>
    </ul>
    <div class="policy" :style="{backgroundImage:`url(${cultureBg})`}">
      <div class="policy-item" v-for="item in cultureFix.list2" :key="item.id">
        <div class="policy-wrap">
          <strong>{{item.title}}</strong>
          <div class="policy-info">
            <p>{{item.description}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
export default {
  data() {
      return {
        menuNumber: 1,
        pbanner: "",
        cultureBg:require('../../assets/culture1.jpg'),
        Pbanner,
        cultureFix: {}
      }
  },
  components:{
    Levalmenu,
    Pbanner
  },
  methods: {
    async getCulture() {
      const { data: res } = await this.$http.get("getCulture");
      this.pbanner = res.data.catinfo.thumb
      this.cultureFix = res.data
    }
  },
  mounted() {
    this.getCulture();
  }
}
</script>

<style lang="less">
  .culture-top{
    padding: 70px 10.41% 130px;
    background-position: center bottom;
    background-size: 100% auto;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  .culture-title{
    text-align: center;
    small{
      display: block;
      margin-bottom: 16px;
      text-align: center;
      line-height: 1;
      color: #d9d9d9;
      font-size: 34px;
      text-transform: uppercase;
    }
    strong{
      display: inline-block;
      position: relative;
      padding: 0 68px;
      line-height: 1;
      color: #000000;
      font-size: 34px;
      &:before,&:after{
        position: absolute;
        top: 50%;
        content: "";
        width: 30px;
        height: 1px;
        background: #030303;
      }
      &:before{
        left: 0;
      }
      &:after{
        right: 0;
      }
      b{
        color: #c4261d;
      }
    }
  }
  .culture-text{
    margin-top: 102px;
    position: relative;
    display: inline-block;
    padding-top: 84px;
    padding-bottom: 80px;
    padding-left: 80px;
    padding-right: 80px;
    &:before{
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 45px;
      height: 33px;
      background: url(../../assets/arrow2.png) center no-repeat;
    }
    &:after{
      position: absolute;
      right: 0;
      bottom: 0;
      content: "";
      width: 45px;
      height: 33px;
      transform: rotate(180deg);
      background: url(../../assets/arrow2.png) center no-repeat;
    }
    strong{
      display: block;
      margin-bottom: 28px;
      line-height: 1;
      color: #333333;
      font-size: 38px;
    }
    span{
      display: block;
      margin-left: -14px;
      margin-bottom: 34px;
      color: #333333;
      font-size: 28px;
      font-weight: bold;
    }
    p{
      color: #333333;
      font-size: 20px;
      line-height: 2;
    }
  }
  .culture-list{
    display: flex;
    li{
      position: relative;
      flex: 1;
      &:hover{
        figure img{
          transform: scale(1.1);
        }
      }
      figure{
        display: block;
        position: relative;
        padding-bottom: 170.8%;
        overflow: hidden;
        img{
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.68s;
        }
      }
    }
  }
  .cuture-in{
    position: absolute;
    left: 0;
    top: 26.92%;
    width: 100%;
    text-align: center;
    color: #fff;
    span{
      display: block;
      margin-bottom: 15px;
      line-height: 1;
      font-size: 16px;
    }
    strong{
      display: block;
      margin-bottom: 24px;
      position: relative;
      padding-bottom: 30px;
      line-height: 1;
      font-size: 24px;
      &:before{
        position: absolute;
        left: 50%;
        margin-left: -11px;
        bottom: 0;
        content: "";
        width: 22px;
        height: 3px;
        background: #fff;
      }
    }
    p{
      font-size: 16px;
      line-height: 32px;
    }
  }
  .policy{
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .policy-item{
    padding: 100px 10.41% 106px;
    border-bottom: 1px solid #f4f4f4;
  }
  .policy-wrap{
    margin-left: 80px;
    strong{
      display: inline-block;
      padding-bottom: 29px;
      position: relative;
      line-height: 1;
      color: #333333;
      font-size: 24px;
      overflow: hidden;
      &:before{
        position: absolute;
        left: 0;
        bottom: 0;
        content: "";
        width: 22px;
        height: 3px;
        background: #c4271e;
        z-index: 3;
        animation: p-ani-6 3.6s linear infinite;
      }
      &:after{
        position: absolute;
        left: 0;
        bottom: 0;
        content: "";
        width: 100%;
        height: 3px;
        background:#f5f5f5;
      }
    }
  }
  @keyframes p-ani-6 {
  0% {
    left: -12px;
  }
  100% {
    left: 100%;
  }
}
  .policy-info{
    margin-top: 36px;
    p{
      color: #333333;
      font-size: 16px;
    }
  }
  .posites{
    display: flex;
    span,a,b{
      display: inline-block;
      color: #ffffff;
      font-size: 14px;
    }
    b{
      margin: 0 5px;
      font-weight: normal;
    }
  }
</style>
<template>
  <div class="about">
    <Pbanner :pbanner="pbanner"/>
     <div class="leval-menu">
        <div class="leval-menu-wrap inner">
          <Levalmenu :menuNumber="menuNumber"/>
          <div class="posites">
            <span>所在位置：</span>
            <router-link to="/home">首页</router-link>
            <b>></b>
            <router-link to="/about/about">关于安吉</router-link>
            <b>></b>
            <router-link to="/about/about">公司简介</router-link>
          </div>
        </div>  
    </div>
    <div class="introduct sAbout-intro">
      <div class="introduct-top" v-if="aboutData.about">
        <strong>{{aboutData.about.sologn1}}<b></b>{{aboutData.about.sologn2}}</strong>
        <div class="introduct-info" v-html="aboutData.about.desc1">
        </div>
        <div class="introduct-text" >
          <p v-html="aboutData.about.desc2"></p>
          <i></i>
        </div>
      </div>
      <div class="scene" data-maxHeight="2232" v-if="aboutData.about && aboutData.about.text.length > 0">
          <div class="aniSpan">
              <div class="sec sec1" data-bottom="1712">
                  <img src="../../assets/bgs-scene-office1.png" alt="" class="puzzle" />
                  <div class="info">
                      <div class="col">
                          <p class="dt"><i class="numCountUp" data-startVal="0" :data-endVal="aboutData.about.text[0].v">{{aboutData.about.text[0].v}}</i>{{aboutData.about.text[0].d}}</p>
                          <p class="dd">{{aboutData.about.text[0].k}}</p>
                      </div>
                      <div class="col">
                          <p class="dt"><i class="numCountUp" data-startVal="0" :data-endVal="aboutData.about.text[1].v">{{aboutData.about.text[1].v}}</i><span class="ffwz">{{aboutData.about.text[1].dd}}</span></p>
                          <p class="dd">{{aboutData.about.text[1].k}}</p>
                      </div>
                  </div>
              </div>
              <div class="sec sec2" data-bottom="1547">
                  <img src="../../assets/bgs-scene-car2.png" alt="" class="puzzle" />

                  <div class="info">
                      <div class="col">
                          <p class="dt"><i class="numCountUp" data-startVal="0" :data-endVal="aboutData.about.text[2].v">{{aboutData.about.text[2].v}}</i>{{aboutData.about.text[2].d}}<span class="ffwz">{{aboutData.about.text[2].dd}}</span></p>
                          <p class="dd">{{aboutData.about.text[2].k}}</p>
                      </div>
                  </div>
              </div>
              <div class="sec sec3" data-bottom="1151">
                  <img src="../../assets/bgs-scene-container3.png" alt="" class="puzzle" />

                  <div class="info">
                      <div class="col">
                          <p class="dt"><i class="numCountUp" data-startVal="0" :data-endVal="aboutData.about.text[3].v">{{aboutData.about.text[3].v}}</i>{{aboutData.about.text[3].d}}<span class="ffwz">{{aboutData.about.text[3].dd}}</span></p>
                          <p class="dd">{{aboutData.about.text[3].k}}</p>
                      </div>
                  </div>
              </div>
              <div class="sec sec4" data-bottom="707">
                  <img src="../../assets/bgs-scene-ship4.png" alt="" class="puzzle" />

                  <div class="info">
                      <div class="col">
                          <p class="dt"><i class="numCountUp" data-startVal="0" :data-endVal="aboutData.about.text[4].v">{{aboutData.about.text[4].v}}</i><span class="ffwz">{{aboutData.about.text[4].dd}}</span></p>
                          <p class="dd">{{aboutData.about.text[4].k}}</p>
                      </div>
                  </div>
              </div>
              <div class="sec sec5" data-bottom="416">
                  <img src="../../assets/bgs-scene-warehouse5.png" alt="" class="puzzle" />

                  <div class="info">
                      <div class="col">
                          <p class="dt"><i class="numCountUp" data-startVal="0" :data-endVal="aboutData.about.text[5].v">{{aboutData.about.text[5].v}}</i>{{aboutData.about.text[5].d}}</p>
                          <p class="dd">{{aboutData.about.text[5].k}}</p>
                      </div>
                  </div>
              </div>
          </div>
          <img src="../../assets/bgs-scene.png" alt="" class="space behind" />
          <img src="../../assets/bgs-scene.png" alt="" class="space front" />
      </div>
    </div>
    <div class="introduct-list" v-if="aboutData.about && aboutData.about.list.length> 0">
      <div class="introduct-item" v-for="item in aboutData.about.list" :key="item.id">
        <figure><img :src="item.thumb" alt=""/></figure>
        <div class="introduct-content">
          <strong>{{item.title}}</strong>
          <div class="introduct-in" v-html="item.desc"></div>
        </div>
      </div>
    </div>
    <!-- <div class="introduct-year" v-if="aboutData.about">
      <figure><img :src="aboutData.about.intro.thumb" alt=""/></figure>
      <div class="development" v-html="aboutData.about.intro.text"></div>
    </div> -->
  </div>
  
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
import { CountUp } from 'countup.js';
export default {
  components: {
    Levalmenu,
    Pbanner
  },
  data() {
    return{
      pbanner: "",
      menuNumber:1,
      introductDa5ta:[
        {
          id:1,
          imgSrc: require("../../assets/16.png"),
          title: "通过30余年的发展",
          desc: "<p>公司已发展成为以水陆联运、公路快运、城际配送、仓储服务、物流信息化管理、国际货代、物流方案策划、供应链管理为一体的5A级综合性现代物流企业集团。安吉物流始终坚持以物流为主、汽车后市场服务为辅的发展道路，物流主业依托五粮液白酒主业发展，并以优质的服务为白酒、粮食、食品饮料、快消品等行业提供物流整体解决方案，同时，公司构建了完整的以汽车销售、汽车维修、汽车驾驶员培训等为主的围绕物流业务需求而发展的汽车后市场服务辅业，同时，公司成立了河南安淮粮业有限公司，涉足粮食采购业务。</p>"
        },{
          id:2,
          imgSrc: require("../../assets/17.png"),
          title: "作为西南地区物流行业的领导者",
          desc: "<p>安吉物流位居中国物流企业百强前列，在国内具有较大的影响力。公司先后获全国物流行业先进集体、全国先进物流企业、中国物流百强企业、中国十大竞争力物流企业、中国（行业）品牌十大创新企业、全国实施卓越绩效模式先进企业、中国服务企业500强、四川企业100强、四川省服务名牌企业、四川省优质服务示范单位等近百余项荣誉。凭借先进的管理理念和健全的管理制度，公司全面通过了ISO9001质量管理体系、ISO14001环境管理体系、OHSAS18001职业健康安全管理认证和能源管理体系认证，形成了独具特色的“四标合一”一体化管理模式。</p>"
        }
      ],
      aboutData: {},
      delepmentData:{
        id: 1,
        imgSrcs: require("../../assets/18.png"),
        desc:"<strong>近年来，公司各项工作迅猛发展<br>2020年，公司营业收入达到75.23亿元。</strong>"
      }
    }
  },
  methods: {
    async getAbout() {
      const { data: res } = await this.$http.get("getAbout");
      this.aboutData = res.data
      console.log(res.data)
      this.pbanner = res.data.catinfo.thumb
    }
  },
  mounted(){
    this.getAbout();
    setTimeout(()=>{
      ;(function($){
        var $b=$("body");
        var w = $(window);
        var $scene = $('.scene');
        var $space = $('.space.behind',$scene);
        var $sec = $('.sec',$scene);
        var nMaxH = $scene.attr("data-maxHeight")/1;
        $scene.height(520*$space.outerWidth()/1200);
        w.on('scroll.sceneAnimate', function(event) {
            var  _st = w.scrollTop()
                ,_lt = $scene.offset().top
                ,_sh = $space.outerHeight()
            ;
            // 压缩比例
            var _radio = $space.outerWidth()/1200;
            var _maxH = nMaxH*_radio;

            var _cha = _st + w.height() - _lt - _sh/3*2;

            _cha = Math.min( _cha < 0 ? 0 : _cha , ( _maxH - _sh ) );

            // 场景高度
            $scene.css({paddingTop:_cha,paddingBottom:/*_cha>0? _sh/2 : */0 });
            $sec.each(function(i,oE) {
                var $oE = $(oE);
                var _bottomMax = $oE.attr("data-bottom")*_radio;
                var _oTl = _cha + _sh - (_maxH - _bottomMax);
                var _bottom = Math.max(0,_oTl);
                $oE.css({bottom:_bottom });

                if(_cha+_bottomMax+_sh/3*2>=_maxH){
                    $oE.addClass('actived');
                    $(".numCountUp",$oE).each(function(ii,oNum) {
                        if(!$(oNum).hasClass("bindedFn")){
                            var numStart = new CountUp(oNum, parseInt($(oNum).attr("data-endVal")), 2);
                            setTimeout(function(){numStart.start(); },100*ii+150);
                            $(oNum).addClass('bindedFn');
                        }
                    });
                }else {
                    $oE.removeClass("actived");
                    $(".numCountUp",$oE).removeClass('bindedFn');
                }
            });


        });
    })(jQuery);
    }, 1000)
      
  }
}
</script>

<style lang="less">
.about{
  padding-bottom: 156px;
  overflow: hidden;
}
  .introduct{
    padding: 90px 0 210px;
  }
  .introduct-top{
    margin: auto;
    width: 894px;
    text-align: center;
    font-family: "微軟正黑體";
    strong{
      display: block;
      line-height: 1.2;
      color: #444444;
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      b{
        display: inline-block;
        margin: 0 14px;
        width: 34px;
        height: 33px;
        background: url(../../assets/introduct.png) center no-repeat;
      }
    }
  }
  .scene-iphone{
    display: none;
  }
  .introduct-info{
    margin: 54px 0 58px;
    text-align: center;
    p{
      color: #444444;
      font-size: 18px;
    }
  }
  .introduct-text{
    position: relative;
    padding-bottom: 30px;
    p{
      color: #444444;
      font-size: 22px;
      line-height: 2;
      font-weight: bold;
    }
    i{
      display: block;
      position: absolute;
      left: 50%;
      margin-left: -200px;
      bottom: 0;
      width: 400px;
      height: 3px;
      background: #ff0000;
      &:before{
        position: absolute;
        left: 50%;
        margin-left: -14px;
        bottom: -12px;
        content: "";
        width: 28px;
        height: 28px;
        background: url(../../assets/arrow5.png) center no-repeat;
      }
    }
  }
  .introduct-item{
    display: flex;
    align-items: center;
    margin-bottom: 170px;
    &:nth-child(2n+2){
      flex-direction: row-reverse;
      .introduct-content{
        margin-left: 0;
        margin-right: 40px;
      }
    }
    figure{
      display: block;
      width: 50%;
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  .introduct-content{
    margin-left: 40px;
    width: 685px;
    max-width: 45%;
    font-family: "微軟正黑體";
    strong{
      display: block;
      line-height: 1.2;
      color: #444444;
      font-size: 30px;
      font-weight: bold;
    }
  }
  .introduct-in{
    margin-top: 20px;
    color: #444444;
    font-size: 18px;
    text-align: justify;
  }
  .introduct-year{
    position: relative;
    width: 1260px;
    margin: auto;
    max-width: 90%;
    figure{
      display: block;
      line-height: 1;
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  .development{
    position: absolute;
    left: 56px;
    bottom: 130px;
    strong{
      display: block;
      color: #fff;
      font-size: 30px;
      font-family: "微軟正黑體";
    }
  }

  .sAbout-intro .scene {
    z-index: 2;
    margin-top: 2.4rem;
    position: relative;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  .sAbout-intro .scene .space {
    z-index: -1;
    pointer-events: none;
    width: 100%;
    height: auto;
  }
  .sAbout-intro .scene .space.behind {
    position: relative;
    opacity: 0;
    visibility: hidden;
  }
  .sAbout-intro .scene .space.front {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .sAbout-intro .scene .aniSpan {
    height: 100%;
  }
  .sAbout-intro .scene .sec {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
  }
  .sAbout-intro .scene .sec .puzzle {
    width: 100%;
    height: auto;
    pointer-events: none;
  }
  .sAbout-intro .scene .sec .info {
    position: absolute;
    top: 0;
    width: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all 250ms ease 0ms;
    transition: all 250ms ease 0ms;
    opacity: 0;
    visibility: hidden;
    /* *visibility: visible; */
    white-space: nowrap;
    font-size: 0;
    border-bottom: #ececec solid 1px;
    padding-bottom: 1rem;
  }
  .sAbout-intro .scene .sec .info .col {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    text-align: left;
    line-height: 1;
  }
  .sAbout-intro .scene .sec .info .col:nth-child(1n+2) {
    margin-left: 1.2rem;
  }
  .sAbout-intro .scene .sec .info .col .dt {
    color: #f00;
    font-family: Arial, sans-serif, 'Microsoft YaHei', '微软雅黑';
    i{
      font-style: normal;
    }
  }
  .sAbout-intro .scene .sec .info .col .dd {
    color: #666666;
    margin-top: 5px;
    line-height: 1.4333;
    white-space: normal;
  }
  .sAbout-intro .scene .sec .ffwz {
    font-family: 'Microsoft JhengHei', '微软正黑体';
  }
  .sAbout-intro .scene .sec.sec1 .info {
    padding-top: 1.6rem;
    padding-left: 2rem;
    left: 50%;
    -webkit-transform: translateX(30%);
            transform: translateX(30%);
  }
  .sAbout-intro .scene .sec.sec2 .info {
    padding-top: 4.4rem;
    padding-left: 2rem;
    left: 50%;
    -webkit-transform: translateX(30%);
            transform: translateX(30%);
  }
  .sAbout-intro .scene .sec.sec3 .info {
    padding-top: 4.1rem;
    padding-right: 1rem;
    right: 50%;
    text-align: center;
    -webkit-transform: translateX(-30%);
            transform: translateX(-30%);
  }
  .sAbout-intro .scene .sec.sec3 .info .col {
    text-align: center;
  }
  .sAbout-intro .scene .sec.sec4 .info {
    padding-top: 1.6rem;
    padding-right: 1rem;
    right: 50%;
    text-align: center;
    -webkit-transform: translateX(-30%);
            transform: translateX(-30%);
  }
  .sAbout-intro .scene .sec.sec4 .info .col {
    text-align: center;
  }
  .sAbout-intro .scene .sec.sec5 .info {
    padding-top: 2.1rem;
    padding-left: 2rem;
    left: 50%;
    -webkit-transform: translateX(30%);
            transform: translateX(30%);
  }
  .sAbout-intro .scene .sec.actived .info {
    -webkit-transition: all 500ms ease 150ms;
    transition: all 500ms ease 150ms;
    opacity: 1;
    visibility: visible;
    -webkit-transform: none !important;
            transform: none !important;
  }
  @media screen and (max-width: 1024px) {
  .dif2 {
    display: block;
  }
}
html {
  font-size: 50px;
}
@media screen and (max-width: 1600px) {
  html {
    font-size: 45px;
  }
}
@media screen and (max-width: 1440px) {
  html {
    font-size: 40px;
  }
}
@media screen and (max-width: 1280px) {
  html {
    font-size: 35px;
  }
}
@media screen and (max-width: 1024px) {
  html {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  html {
    font-size: 25px;
  }
}
@media screen and (max-width: 480px) {
  html {
    font-size: 20px;
  }
}
@media screen and (min-width: 1025px) {
  .fzRes {
    font-size: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .fzRes {
    font-size: 95px;
  }
}
@media screen and (max-width: 950px) {
  .fzRes {
    font-size: 90px;
  }
}
@media screen and (max-width: 900px) {
  .fzRes {
    font-size: 85px;
  }
}
@media screen and (max-width: 850px) {
  .fzRes {
    font-size: 80px;
  }
}
@media screen and (max-width: 800px) {
  .fzRes {
    font-size: 75px;
  }
}
@media screen and (max-width: 750px) {
  .fzRes {
    font-size: 70px;
  }
}
@media screen and (max-width: 700px) {
  .fzRes {
    font-size: 65px;
  }
}
@media screen and (max-width: 650px) {
  .fzRes {
    font-size: 60px;
  }
}
@media screen and (max-width: 600px) {
  .fzRes {
    font-size: 55px;
  }
}
@media screen and (max-width: 550px) {
  .fzRes {
    font-size: 50px;
  }
}
@media screen and (max-width: 500px) {
  .fzRes {
    font-size: 45px;
  }
}
.fz60 {
  font-size: 60px;
}
@media screen and (max-width: 1440px) {
  .fz60 {
    font-size: 54px;
  }
}
@media screen and (max-width: 1024px) {
  .fz60 {
    font-size: 46px;
  }
}
@media screen and (max-width: 768px) {
  .fz60 {
    font-size: 40px;
  }
}
.fz48,
.sAbout-intro .scene .sec .info .col .dt {
  font-size: 48px;
}
@media screen and (max-width: 1440px) {
  .fz48,
  .sAbout-intro .scene .sec .info .col .dt {
    font-size: 44px;
  }
}
@media screen and (max-width: 1024px) {
  .fz48,
  .sAbout-intro .scene .sec .info .col .dt {
    font-size: 40px;
  }
}
@media screen and (max-width: 768px) {
  .fz48,
  .sAbout-intro .scene .sec .info .col .dt {
    font-size: 34px;
  }
}
.fz40,
.sAbout-intro .txt .p0 {
  font-size: 40px;
}
@media screen and (max-width: 1440px) {
  .fz40,
  .sAbout-intro .txt .p0 {
    font-size: 36px;
  }
}
@media screen and (max-width: 1024px) {
  .fz40,
  .sAbout-intro .txt .p0 {
    font-size: 32px;
  }
}
@media screen and (max-width: 768px) {
  .fz40,
  .sAbout-intro .txt .p0 {
    font-size: 26px;
  }
}
.fz36 {
  font-size: 36px;
}
@media screen and (max-width: 1440px) {
  .fz36 {
    font-size: 32px;
  }
}
@media screen and (max-width: 1024px) {
  .fz36 {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .fz36 {
    font-size: 24px;
  }
}
.fz32 {
  font-size: 32px;
}
@media screen and (max-width: 1440px) {
  .fz32 {
    font-size: 28px;
  }
}
@media screen and (max-width: 1024px) {
  .fz32 {
    font-size: 26px;
  }
}
@media screen and (max-width: 768px) {
  .fz32 {
    font-size: 22px;
  }
}
.fz28,
.sAbout-intro .scene .sec .ffwz {
  font-size: 28px;
}
@media screen and (max-width: 1440px) {
  .fz28,
  .sAbout-intro .scene .sec .ffwz {
    font-size: 24px;
  }
}
@media screen and (max-width: 1024px) {
  .fz28,
  .sAbout-intro .scene .sec .ffwz {
    font-size: 22px;
  }
}
@media screen and (max-width: 768px) {
  .fz28,
  .sAbout-intro .scene .sec .ffwz {
    font-size: 20px;
  }
}
.fz24,
.sAbout-intro .txt .p2 {
  font-size: 24px;
}
@media screen and (max-width: 1440px) {
  .fz24,
  .sAbout-intro .txt .p2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .fz24,
  .sAbout-intro .txt .p2 {
    font-size: 18px;
  }
}
.fz20 {
  font-size: 20px;
}
@media screen and (max-width: 1440px) {
  .fz20 {
    font-size: 18px;
  }
}
@media screen and (max-width: 1024px) {
  .fz20 {
    font-size: 16px;
  }
}
.fz18,
.sAbout-intro .txt .p1,
.sAbout-intro .scene .sec .info .col .dd {
  font-size: 18px;
}
@media screen and (max-width: 1440px) {
  .fz18,
  .sAbout-intro .txt .p1,
  .sAbout-intro .scene .sec .info .col .dd {
    font-size: 16px;
  }
}
.fz16,
.imax {
  font-size: 16px;
}
@media screen and (max-width: 1440px) {
  .fz16,
  .imax {
    font-size: 14px;
  }
}
.fz14 {
  font-size: 14px;
}
@media screen and (max-width: 1440px) {
  .fz14 {
    font-size: 12px;
  }
}
</style>
import { render, staticRenderFns } from "./Honor.vue?vue&type=template&id=10d37b00"
import script from "./Honor.vue?vue&type=script&lang=js"
export * from "./Honor.vue?vue&type=script&lang=js"
import style0 from "./Honor.vue?vue&type=style&index=0&id=10d37b00&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
    <div class="container">
        <!-- <Pbanner :pbanner="pbanner"/> -->
        <transition name="fade">
            <router-view></router-view>
        </transition>
         <Footer/>
    </div>
</template>

<script>
//import Pbanner from '../components/Pbanner';
import Footer from '../components/Footer';
export default {
    data(){
        return {
            pbanner: require('../assets/3.jpg')
        }
    },
    components: {
        Footer,
        //Pbanner
    },
}
</script>

<style>

</style>
<template>
    <div class="honor-box">
        <Pbanner :pbanner="pbanner"/>
         <div class="leval-menu">
            <div class="leval-menu-wrap inner">
                <Levalmenu :menuNumber="menuNumber"/>
                <div class="posites">
                    <span>所在位置：</span>
                    <router-link to="/home">首页</router-link>
                    <b>></b>
                    <router-link to="/about/about">关于安吉</router-link>
                    <b>></b>
                    <router-link to="/about/honor">资质荣誉</router-link>
                </div>
            </div>  
        </div>
        <div class="honor" :style="{backgroundImage:`url(${honorBg})`}">
            <div class="honor-wraps" v-if="honorData.catinfo">
                <div class="culture-title">
                    <small>{{honorData.catinfo.ename}}</small>
                    <strong>资质<b>荣誉</b></strong>
                </div>
                <div class="honor-info">
                    <p>{{honorData.catinfo.description}}</p>
                </div>
                <div class="honor-swiper">
                    <swiper :options="swiperOption" ref="mySwiper">
                        <swiper-slide class="honor-item" v-for="(item, index) in honorData.list" :key="index">
                            <figure><img :src="item.thumb" :alt="item.title"/></figure>
                            <span>{{item.title}}</span>
                        </swiper-slide>
                    </swiper>
                    <div class="honor-btns">
                        <span class="iconfont prev">→</span>
                        <span class="iconfont next">→</span>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
    data() {
        return {
            pbanner: "",
            menuNumber: 1,
            honorBg:require('../../assets/13.jpg'),
            honorData: [],
            swiperOption: {
                loop: true,
                speed:1000,
                centeredSlides : true,
                slideToClickedSlide: true,
                parallax : true,
                slidesPerView :3,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                observeParents:false,   
                observer:true,
                navigation: {
                    nextEl: '.honor-btns .next',
                    prevEl: '.honor-btns .prev',
                },
                breakpoints: { 
                    640: {  
                    slidesPerView: 1,
                    },
                }
            }
        }
    },
    components:{
        Levalmenu,
        Pbanner,
        swiper,
        swiperSlide
    },
    methods: {
        async getHonorData(){
            const {data: res} = await this.$http.post("getHonorinfo");
            this.pbanner = res.data.catinfo.thumb
            if(res.status == 1){
                this.honorData = res.data;
            }
        },
    },
    mounted() {
        this.getHonorData();
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        }
    },
}
</script>

<style lang="less">
    .honor{
        padding: 70px 0 84px;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
    }
    .honor-wraps{
        padding: 0 10.41%;
    }
    .honor-info{
        margin-top: 44px;
        margin-bottom: 100px;
        p{
            color: #333333;
            font-size: 16px;
            text-align: center;
            line-height: 2;
        }
    }
    .honor-swiper{
        position: relative;
        z-index: 2;
        &:before{
            position: absolute;
            left: 50%;
            margin-left: -296px;
            bottom: 0;
            z-index: -1;
            content: "";
            width: 593px;
            height: 198px;
            background: url(../../assets/honorbg.png) center no-repeat;
        }
    }
    .honor-item{
        &.swiper-slide-active{
            figure{
                transform: scale(1);
                filter: blur(0px);
            }
            span{
                opacity: 1;
                visibility: visible;
            }
        }
        figure{
            position: relative;
            width: auto;
            height: 282px;
            display: block;
            transform: scale(0.8);
            transition: 0.58s;
            filter: blur(6px);
            img{
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
        span{
            display: block;
            margin-top: 90px;
            text-align: center;
            line-height: 1.2;
            color: #333333;
            font-size: 24px;
            opacity: 0;
            visibility: hidden;
            transition: 0.58s;
        }
    }
    .honor-btns{
        position: absolute;
        left: 50%;
        margin-left: -24%;
        top: 110px;
        z-index: 10;
        width: 48%;
        display: flex;
        justify-content: space-between;
        span{
            display: block;
            width: 60px;
            height: 60px;
            text-align: center;
            line-height: 60px;
            border-radius: 50%;
            color: #c4261d;
            font-size: 24px;
            box-shadow: 0 0 14px rgba(0,0,0,0.11);
            transition: 0.36s;
            cursor: pointer;
            background: #fff;
            &.prev{
                transform: rotate(180deg);
            }
            &:hover{
                background: #c4261d;
                color: #fff;
                box-shadow: none;
            }
        }
    }
    .culture-title{
    text-align: center;
    small{
      display: block;
      margin-bottom: 16px;
      text-align: center;
      line-height: 1;
      color: #d9d9d9;
      font-size: 34px;
      text-transform: uppercase;
    }
    strong{
      display: inline-block;
      position: relative;
      padding: 0 68px;
      line-height: 1;
      color: #000000;
      font-size: 34px;
      &:before,&:after{
        position: absolute;
        top: 50%;
        content: "";
        width: 30px;
        height: 1px;
        background: #030303;
      }
      &:before{
        left: 0;
      }
      &:after{
        right: 0;
      }
      b{
        color: #c4261d;
      }
    }
  }
</style>
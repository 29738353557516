<template>
  <div class="companys">
    <Pbanner :pbanner="pbanner"/>
    <div class="leval-menu">
        <div class="leval-menu-wrap inner">
          <Levalmenu :menuNumber="menuNumber"/>
          <div class="posites">
            <span>所在位置：</span>
            <router-link to="/home">首页</router-link>
            <b>></b>
            <router-link to="/about/about">关于安吉</router-link>
            <b>></b>
            <router-link to="/about/subsidiary">子公司简介</router-link>
          </div>
        </div>  
    </div>
    <div class="company-tabs"  :style="{backgroundImage:`url(${companyBg})`}">
      <div class="company-btn">
        <ul class="company-btn-list">
          <li v-for="(item,index) in compantData" :key="item.cid+2" :class="index==num?'active':''" @click="toggleTab(index)">
            <span>{{item.title}}</span>
            <div class="company-texts" v-show="index==num" v-html="item.content">

            </div>
          </li>
        </ul>
      </div>
      <div class="company-list">
          <transition-group name="slide-fade">
            <div class="company-items" v-for="(item1,index) in compantData" :key="item1.cid+1" v-show="index==num">
            <div class="company-titles">
              <strong>{{item1.title}}</strong>
              <small>{{item1.en_title}}</small>
            </div>
            <div class="company-infos" v-html="item1.content">
                        
            </div>
          </div>
        </transition-group>
        
      </div>
      <el-collapse accordion class="company-ip">
          <el-collapse-item class="companys-item" v-for="(item) in compantData" :key="item.cid">
              <template slot="title">
                  <div class="company-btn-list">
                    <div class="company-tts">
                      <span>{{item.title}}</span>
  
                    </div>
                  </div>
              </template>
              <div class="company-texts" v-html="item.content">

              </div>
          </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
export default {
  data() {
      return {
          pbanner: "",
          menuNumber: 1,
          companyBg: require("../../assets/12_1.jpg"),
          compantData: [],
          num: 0
      }
   },
  components:{
      Levalmenu,
      Pbanner
  },
  methods: {
    async getCompant(){
        const {data: res} = await this.$http.post("getChildCompany");
        if(res.status == 1){
          this.compantData = res.data.list;
          this.pbanner = res.data.catinfo.thumb
        }
    },
    toggleTab(index){
      this.num = index;
    }
  },
  mounted() {
    this.getCompant();
  }
}
</script>

<style lang="less">
  .company-tabs{
    padding: 102px 10.41% 155px;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
  }
  .company-ip{
    display: none;
  }
  .company-btn{
    width: 36.6%;
  }
  .company-ip .el-collapse-item__header{
    background: none;
    height: auto;
    border-bottom: none;
  }
  #app .company-btn-list{
    width: 100%;
    display: block;
    
  }
  #app .company-ip .el-collapse-item__arrow{
      display: none;
    }
  .company-btn-list{
    li,.company-tts{
      position: relative;
      border-bottom: 1px solid #dadbdd;
      cursor: pointer;
      &:hover,&.active{
        span{
          color: #c4261d;
          &:after{
            background: url(../../assets/arrow4.png) center no-repeat;
          }
        }
      }
      &:before{
        position: absolute;
        left: 0;
        bottom: -1px;
        content: "";
        width: 22px;
        height: 1px;
        background: #c4261d;
        animation: p-ani-6 3.6s linear infinite;
      }
      span{
        display: flex;
        align-items: center;
        padding-right: 30px;
        position: relative;
        padding-left: 21px;
        color: #333333;
        font-size: 22px;
        height: 78px;
        line-height: 1.2;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        transition: 0.34s;
        &:before{
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -4px;
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #c4261d;
        }
        &:after{
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -7px;
          content: "";
          width: 13px;
          height: 15px;
          background: url(../../assets/arrow3.png) center no-repeat;
          transition: 0.34s;
        }
      }
    }
  }
.company-btn-list li:nth-of-type(1):before {
  animation-delay: 3.6s;
}
.company-btn-list li:nth-of-type(2):before {
  animation-delay: 3.2s;
}
.company-btn-list li:nth-of-type(3):before {
  animation-delay: 2.8s;
}
.company-btn-list li:nth-of-type(4):before {
  animation-delay: 2.4s;
}
.company-btn-list li:nth-of-type(5):before {
  animation-delay: 2s;
}
.company-btn-list li:nth-of-type(6):before {
  animation-delay: 1.6s;
}
.company-btn-list li:nth-of-type(7):before {
  animation-delay: 1.2s;
}
.company-btn-list li:nth-of-type(8):before {
  animation-delay: 0.8s;
}
.company-btn-list li:nth-of-type(9):before {
  animation-delay: 0.4s;
}
@keyframes p-ani-6 {
  0% {
    left: -12px;
  }
  100% {
    left: 100%;
  }
}
.company-list{
  width: 57.05%;
}
.company-items{
  padding-top: 20px;
}
.company-titles{
  position: relative;
  padding-bottom: 28px;
  margin-bottom: 32px;
  &:before{
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 39px;
    height: 3px;
    background: #c4261d;
  }
  strong{
    display: block;
    margin-bottom: 20px;
    line-height: 1;
    color: #333333;
    font-size: 28px;
  }
  small{
    display: block;
    line-height: 1;
    color: #999999;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
  }
}
.company-infos,.company-texts{
  position: relative;
  padding-right: 50px;
  max-height: 572px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  color: #333333;
  font-size: 16px;
  line-height: 2;
  text-align: justify;
  img{
    max-width: 100%;
    width: auto;
    height: auto;
  }
}
.company-texts{
  margin-bottom: 20px;
  max-height: none;
  font-size: 14px;
  padding-right: 0px;
  display: none;
  background: none;
  max-height: auto;
}
.companys-item .el-collapse-item__wrap{
  background: none;
  margin-bottom: 0;
  padding-top: 20px;
  .el-collapse-item__content{
    padding-bottom: 0;
    border-bottom: none;
  }
}
.company-infos::-webkit-scrollbar {
  width: 3px;
  border-radius: 1px;
  background-color: #ececec;
}

.company-infos::-webkit-scrollbar-track {
  border-radius: 1px;
  background-color: #ececec;
}

.company-infos::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: #c4261d;
}
.slide-fade-enter-active {transition: all 0.5s;}
.slide-fade-leave-active {transition: 0 ;}
.slide-fade-enter, .slide-fade-leave-to{transform: translateY(40px);opacity: 0;}

</style>
<template>
    <div class="container">
        <Pbanner :pbanner="pbanner"/>
        <div class="leval-menu">
            <div class="leval-menu-wrap inner">
            <Levalmenu :menuNumber="menuNumber"/>
            <div class="posites">
                <span>所在位置：</span>
                <router-link to="/home">首页</router-link>
                <b>></b>
                <router-link to="/about/about">关于安吉</router-link>
                <b>></b>
                <router-link to="/about/qtjl">群团聚力</router-link>
            </div>
            </div>  
        </div>
        <div class="party-news">
            <div class="party-tab" @mouseenter="on_bot_enter" @mouseleave="on_bot_leave">
                <div class="party-wraps">
                    <swiper class="party-swiper" :options="swiperOption" ref="mySwiper" v-if='partyData.newlist'>
                        <swiper-slide class="hot-item" v-for="(item, index) in partyData.newlist" :key="index">
                        <div class="party-item">
                            <router-link :to="'/partyact/'+item.cid">
                                <div class="party-imgs">
                                    <figure>
                                        <img :src="item.thumb" :alt="item.title"/>
                                    </figure>
                                </div>
                                <div class="party-right">
                                    <strong>{{item.title}}</strong>
                                    <time>{{item.create_time}}</time>
                                    <p>{{item.description}}</p>
                                    <span>查看详情</span>
                                </div>
                            </router-link>
                        </div>
                        </swiper-slide>
                    </swiper>
                    <div class="party-swiper-dit">
                        <span class="prev iconfont">&#xe608;</span>
                        <div class="swiper-pagination" slot="pagination"></div>
                        <span class="next iconfont">&#xe607;</span>
                    </div>
                </div>
            </div>
            <div class="party-down">
                <div class="party-bg">
                     <ul class="party-news-list" v-if='newsList'>
                        <li v-for="item in newsList.slice(0,3)" :key="item.cid" >
                            <router-link :to="'/partyact/'+item.cid">
                                <figure>
                                    <img :src="item.thumb" alt=""/>
                                    <span>{{item.title}}</span>
                                </figure>
                            </router-link>
                        </li>
                    </ul>
                    <ul class="party-lists">
                        <li v-for="item in newsList" :key="item.cid">
                             <router-link :to="'/partyact/'+item.cid">
                                <p>{{item.title}}</p>
                                <time>{{item.create_time}}</time>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="user-pagination">
                    <el-button type="button" @click="FirstPage" class="my-btn">首页</el-button>
                    <el-pagination ref="pagination"
                     prev-text="上一页" next-text="下一页"
                        layout="prev, pager, next, slot"
                        @current-change="handleCurrentChange"
                        :current-page="queryInfo.page"
                        :total="total"
                        :page-size="pagesize"
                        >
                    </el-pagination>
                    <el-button type="button" @click="LastPage" class="my-btn" style="margin: 0px 5px">尾页</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
export default {
    data(){
        return {
            pbanner:"",
            menuNumber: 1,
            partyData: [],
            HotList:[],
            newsList: [],
            queryInfo: {
                catid: 12,
                page: 1
            },
            total: 0,
            pagesize: 15,
            swiperOption: {
                loop: true,
                speed:1000,
                parallax : true,
                spaceBetween : 20,
                autoplay: {
                    delay: 4000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                observeParents:false,   
                observer:true,
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: '.party-swiper-dit .next',
                    prevEl: '.party-swiper-dit .prev',
                },
            }
        }   
    },
    components: {
        Pbanner,
        Levalmenu,
    },
    methods: {
        async getParty(){
            var qs = require('qs');
            const {data: res} = await this.$http.post("getParty",qs.stringify(this.queryInfo));
            if(res.status == 1){
                this.partyData = res.data;
                this.HotList = res.data.newlist;
                this.newsList = res.data.list;
                this.total = Number(res.data.pageinfo.count);
                this.pbanner = res.data.catinfo.thumb
            }else{
                this.$message.error('数据请求失败');
            }
        },
        on_bot_enter() {
            this.$refs.mySwiper.swiper.autoplay.stop()
        },
        on_bot_leave() {
            this.$refs.mySwiper.swiper.autoplay.start()
        },
        handleCurrentChange(newPage){
            this.queryInfo.page = newPage;
            this.getParty();
           
        },
        FirstPage(){
            this.$refs.pagination.handleCurrentChange(1);
            this.$emit('handleCurrentChange', 1);
        },
        LastPage(){
            let font = this.$refs.pagination
            let cpage = Math.ceil(font.total / font.pageSize);
            font.handleCurrentChange(cpage);
        }
    },
    mounted(){
        this.getParty();
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
           
        },
    },
}
</script>

<style lang="less">
    .party-tab{
        position: relative;
        padding: 80px 10.04%;
    }
    .party-wraps{
        position: relative;
    }
    .party-news{
        padding-bottom: 60px;
    }
    .party-item{
        a{
            display: flex;
            justify-content: space-between;
            &:hover{
                figure img{
                    transform: scale(1.06);
                }
                .party-right{
                    span{
                        background: #c4261d;
                        color: #fff;
                        border-color: #c4261d;
                    }
                }
            }
        }
    }
    .party-imgs{
        position: relative;
        width: 50%;
        figure{
            display: block;
            position: relative;
            line-height: 1;
            overflow: hidden;
            padding-bottom: 62.63%;
            img{
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                object-fit: cover;
                width: 100%;
                height: 100%;
                transition: 0.78s;
            }
        }
    }
    .party-right{
        position: relative;
        padding-top: 18px;
        width: 46.05%;
        strong{
            display: block;
            line-height: 1.5;
            color: #333333;
            font-size: 30px;
            font-weight: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        time{
            display: block;
            margin-top: 30px;
            margin-bottom: 36px;
            line-height: 1;
            color: #999999;
            font-size: 16px;
        }
        p{
            color: #666666;
            font-size: 16px;
            line-height: 1.8;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
        }
        span{
            display: block;
            position: absolute;
            left: 0;
            bottom: 25px;
            width: 136px;
            height: 41px;
            border: 1px solid #c8c7c7;
            border-radius: 22px;
            color: #333333;
            font-size: 16px;
            line-height: 41px;
            text-align: center;
            transition: 0.45s;
        }
    }
    .party-swiper-dit{
        position: absolute;
        right: 0;
        bottom: 35px;
        display: flex;
        align-items: center;
        z-index: 10;
        .iconfont{
            display: inline-block;
            line-height: 1;
            color: #999999;
            font-size: 20px;
            cursor: pointer;
            &:hover{
                color: #9b0000;
            }
        }
        .swiper-pagination{
            margin: 0 14px;
            position: relative;
            .swiper-pagination-bullet{
                width: 10px;
                height: 10px;
                background: #a9a9a9;
                margin: 0;
                margin-right: 12px;
                opacity: 1;
                &.swiper-pagination-bullet-active{
                    background: #9b0000;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .party-bg{
        padding: 74px 10.41% 42px;
        background: #f8f8f8;
    }
    .party-news-list{
        display: flex;
        li{
            margin-right: 2.6%;
            width: 31.6%;
            &:last-child{
                margin-right: 0;
            }
            a:hover{
                figure img{
                    transform: scale(1.07);
                }
            }
            figure{
                position: relative;
                line-height: 1;
                padding-bottom: 62.5%;
                overflow: hidden;
                img{
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: 0.65s;
                }
                span{
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    box-sizing: border-box;
                    padding: 0 20px;
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    color: #fff;
                    font-size: 16px;
                    font-weight: bold;
                    background: rgba(0,0,0,0.6);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .party-lists{
        margin-top: 38px;
        display:flex;
        flex-wrap: wrap;
        li{
            margin-right: 2.6%;
            margin-bottom: 10px;
            width: 31.6%;
            &:nth-child( -n + 3){
                display: none;
            }
            &:nth-child(3n+3){
                margin-right: 0;
            }
            a{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &:hover{
                    p{
                        color: #c4261d;
                        &:before{
                            background: #c4261d;
                        }
                    }
                }
                p{
                    position: relative;
                    padding-left: 20px;
                    max-width: 70%;
                    color: #333333;
                    font-size: 16px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    transition: 0.38s;
                    &:before{
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin-top: -4px;
                        content: "";
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #2c2c2c;
                        transition: 0.38s;
                    }
                }
                time{
                    display: block;
                    color: #666666;
                    font-size: 14px;
                }
            }
        }
    }
    .party-item{
        width: 100%;
    }
    .party-pagination{
        padding: 40px 0;
        display: flex;
        justify-content: center;
        .el-pagination{
            padding: 0;
            margin: 0;
            display: flex;
            font-weight: normal;
            li{
                width: 58px;
                height: 58px;
                text-align: center;
                line-height: 58px;
                color: #999999 !important;
                font-size: 15px;
                border-radius: 0 !important;
                padding: 0;
                margin: 0 1px;
                background: #f3f4f5 !important;
                &.active,&:hover{
                    background: #c4261d !important;
                    color: #fff !important;
                }
            }
        }
        .btn-prev,.btn-next{
            margin: 0 1px !important;
            padding: 0 !important;
            height: 58px !important;
            span{
                padding: 0;
                margin: 0;
                width: 108px;
                height: 58px !important;
                line-height: 58px !important;
                text-align: center;
                line-height: 58px;
                font-size: 15px;
                color: #999999;
                background: #f3f4f5;
                border-radius: 0px !important;
            }
        }
        .el-button{
            margin: 0 !important;
            padding: 0 !important;
            border: none !important;
            span{
                display: block;
                height: 58px;
                line-height: 58px;
                width: 88px;
                text-align: center;
                background: #f3f4f5;
                color: #999999;
                font-size: 15px;
                transition: 0.4s;
                &:hover{
                    background: #c4261d;
                    color: #fff;
                }
            }
        }
    }
    .user-pagination{
        display: flex;
        justify-content: center;
        margin-top: 60px;
        .el-button{
            width: 63px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #dddddd;
            border-radius: 0;
            color: #666666;
            font-size: 12px;
            padding: 0;
            transition: 0.4s;
            &:hover{
                border-color: #e50012;
                background: #e50012;
                color: #fff;
            }
        }
         .el-pagination.is-background .el-pager li{
             background: none;
         }
         .el-pagination{
             padding: 0;
             padding-left: 3px;
         }
        .el-pager li{
            margin: 0 4px !important;
            width: 30px;
            height: 30px;
            border: 1px solid #dddddd;
            line-height: 28px !important;
            color: #666666;
            font-weight: normal;
            font-size: 12px;
            background: none;
            transition: 0.36s;
            padding: 0;
            border-radius: 0 !important;
            background: none;
            &:hover,&.active{
                color: #fff;
                border-color: #e50012;
                background: #e50012;
            }
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active{
            color: #fff;
                border-color: #e50012;
                background: #e50012;
        }
        .el-pagination.is-background .el-pager li:not(.disabled):hover{
            color: #fff;
                border-color: #e50012;
                background: #e50012;
        }
        .btn-prev,.btn-next{
            background: transparent !important;
        }
        .el-button:hover{
            color: #fff;
            border-color: #e50012;
            background: #e50012;
        }
        .el-button:focus{
             color: #666666;
            border-color: #dddddd;
            background: none;
        }
        .btn-prev span,.btn-next span{
            display: inline-flex;
            padding: 0 12px !important;
            height: 30px !important;
            line-height: 30px !important;
            border: 1px solid #dddddd;
            color: #666666;
            font-weight: normal;
            font-size: 12px !important;
            background: none;
            border-radius: 0px !important;
            background-color: transparent !important;
        }
        .el-pager li.active+li{
            border-left: 1px solid #ddd;
        }
    }
</style>
<template>
    <div class="honor-box">
      <Pbanner :pbanner="pbanner"/>
        <div class="leval-menu">
          <div class="leval-menu-wrap inner">
              <Levalmenu :menuNumber="menuNumber"/>
              <div class="posites">
                  <span>所在位置：</span>
                  <router-link to="/home">首页</router-link>
                  <b>></b>
                  <router-link to="/about/about">关于安吉</router-link>
                  <b>></b>
                  <router-link to="/about/organize">组织机构</router-link>
              </div>
          </div>  
      </div>
      <section class="organize-box" :style="{backgroundImage:`url(${honorBg})`}">
        <div class="culture-title">
          <small>Organizational structure</small>
          <strong>组织<b>机构</b></strong>
        </div>
        <div class="organize-wrap" v-if="orgranizeData.info" v-html="orgranizeData.info.content">
        </div>
      </section> 
    </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
export default {
    data() {
        return {
            pbanner: "",
            menuNumber: 1,
            honorBg:require('../../assets/13.jpg'),
            orgranizeData: [],
        }
    },
    components:{
      Levalmenu,
      Pbanner
    },
    methods: {
        async getHonorData(){
            const {data: res} = await this.$http.post("getPageInfo?catid=29");
            console.log(res)
            if(res.status == 1){
                this.orgranizeData = res.data;
            }
            this.pbanner = res.data.catinfo.thumb
        },
    },
    mounted() {
        this.getHonorData();
    },
}
</script>

<style lang="less">
.organize-box{
  padding: 70px 0 12.4vw;
  background-attachment: fixed;
  background-position: center; 
  background-repeat: no-repeat;
  background-size: cover;
}
.culture-title{
  text-align: center;
  small{
    display: block;
    margin-bottom: 16px;
    text-align: center;
    line-height: 1;
    color: #d9d9d9;
    font-size: 34px;
    text-transform: uppercase;
  }
  strong{
    display: inline-block;
    position: relative;
    padding: 0 68px;
    line-height: 1;
    color: #000000;
    font-size: 34px;
    &:before,&:after{
      position: absolute;
      top: 50%;
      content: "";
      width: 30px;
      height: 1px;
      background: #030303;
    }
    &:before{
      left: 0;
    }
    &:after{
      right: 0;
    }
    b{
      color: #c4261d;
    }
  }
}
.organize-wrap{
  margin-top: 4.27vw;
  padding: 0 10.42vw;
  text-align: center;
  img{
    display: block;
    margin: auto;
    max-width: 100%;
    height: auto;
  }
}

</style>